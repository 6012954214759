import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './staff.reducer'

export const selectFeature = createFeatureSelector<State>('staff')

export const getStaffList = createSelector(
    selectFeature,
    (state: any) => state.staff
);

export const getError = createSelector(
    selectFeature,
    (state: any) => state.error
);